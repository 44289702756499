/* Reset margins and padding */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Set html and body to full height and width */
html, body {
    height: 100%;
    width: 100%;
    overflow-x: hidden; /* Prevents horizontal scrolling */
}

/* If you have a root div for React, like <div id="root"></div>, make sure it also fills the page */
#root {
    height: 100%;
}
