.beta-banner {
    position: fixed;
    width: 120px;
    height: 40px;
    background: #EE8E4A;
    top: 55px;
    left: -30px;
    text-align: center;
    font-size: 16px;
    font-family: sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    line-height: 40px;
    transform: rotate(-45deg);
    transform-origin: top left;
    z-index: 1000;
}
