/* App.css */

@font-face {
    font-family: 'NoicheRegular';
    src: url('./fonts/noiche_regular-webfont.woff2') format('truetype');
}

@font-face {
    font-family: 'NoicheBold';
    src: url('./fonts/noiche_bold-webfont.woff2') format('truetype');
}

.App {
    font-family: 'NoicheRegular', sans-serif;
    font-size: 1.2em;
    background-color: #FBF6ED;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25em;
    box-sizing: border-box;
    color: #000000;
    overflow: hidden;
    position: relative;
}

.ChatBox {
    background-color: #FBF6ED;
    border-radius: 0.5em;
    width: 80%;
    max-width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 0.25em 1em rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    position: fixed;
}

.ChatBox-footer, .ChatBox-header {
    padding: 0.9375em;
    background-color: #FFFFFF;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.ChatBox-header {
    font-family: 'NoicheBold', sans-serif;
    color: #000000;
    font-size: 1.8em;
    font-weight: 500;
    border-bottom: 0.0625em solid #FFEFD5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3125em;
}


.ChatBox-footer {
    border-top: 0.0625em solid #FFEFD5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ChatBox-content {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
}

.ChatBox-content p {
    margin-bottom: 15px;
    padding: 10px 15px;
    border-radius: 16px;
    font-size: 1.5em;
    line-height: 1.4;
    position: relative;
}

.ChatBox-content p.user {
    background-color: #FBF6ED ;
    align-self: flex-end;
    order: 2;
    color: #FBF6ED ;
}

.ChatBox-content p.ai {
    background-color: #c0c3c8;
    align-self: flex-start;
    order: 1;
    color: #FBF6ED ;
}

.ChatBox-content p.ai::before {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-right-color: #c0c3c8;
    border-left: 0;
    border-bottom: 0;
}

.ChatBox-content p.user::after {
    content: '';
    position: absolute;
    top: 10px;
    right: -10px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-left-color: #3a3f47;
    border-right: 0;
    border-bottom: 0;
}

.ChatBox-input {
    font-family: 'NoicheRegular', sans-serif;
    font-size: 1em;
    width: 80%;
    padding: 10px;
    border-radius: 8px;
    border: none;
    outline: none;
    background-color: #FFEFD5;
    color: #333333;
    transition: box-shadow 0.3s;
}

.ChatBox-input:focus {
    box-shadow: 0 0 3px #000000ad;
}

.ChatBox-button {
    background-color: #77E8D2;
    color: #000000;
    border: none;
    padding: 10px 20px;
    margin-left: 10px;
    margin-bottom: 1px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    width: 10em;
    text-align: center;
}

.ChatBox-button:hover {
    background-color: #62e6cb;
    transform: scale(1.05);
}

.ChatBox-button:disabled {
    background-color: #77e8d183;  
    cursor: not-allowed;
    transform: scale(1);
}

.ChatBox-button:active {
    background-color: #77E8D2;
    transform: scale(1);
}
@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.ChatBox-content p.ai.typing::after {
    content: '|';
    animation: blink 0.7s infinite;
    display: inline-block;
    margin-left: 5px;
}

.message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
}

.message.user {
    align-items: flex-end;
}

.message > strong {
    margin-bottom: 5px;
}

.bubble {
    max-width: 60%;  
    padding: 10px 12px;  
    border-radius: 15px;  
    font-size: 1em;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin: 5px 0;
    display: flex;          
    align-items: center;
    justify-content: center;
}

.bubble p {
    margin: 0;
    padding: 0;
}


.bubble.ai {
    background-color: #FFEFD5;
    color: #000000;
    min-height: 40px;       
    display: flex;         
    align-items: center;
}


.bubble.ai::before {
    display: none;
}

.bubble.user {
    background-color: #FFFFFF;
    color: #000000;
}

.bubble.user::after {
    display: none;
}


.clear-button {
    background-color: #FF9863; 
    color: #000000;
    border: none;
    padding: 10px 20px;
    margin-left: 11px;
    margin-top: 1px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.clear-button:hover {
    background-color: #fd8244;  
}

.loading-dots {
    display: flex;
    justify-content: center;  
    align-items: center;      
    width: fit-content;      
    margin: auto;            
  }
  
  
  @keyframes dotBlink {
    0%, 20%, 50%, 80%, 100% {
      opacity: 0.5;
    }
    40% {
      opacity: 1;
    }
    60% {
      opacity: 0.5;
    }
  }
  
  .dot {
    background-color: #000;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 0 2px;
    animation: blink 1.4s infinite;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }

  .chatbox-logo {
    width: 2.5em;
    height: auto;
}

.message-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .message-time {
    margin-right: 10px;
  }
  
  .feedback-icons {
    display: flex;
    gap: 5px; /* Adjust the space between buttons */
  }
  
  .feedback-button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    /* Additional styling for buttons as needed */
  }


  .ChatBox-disclaimer {
    text-align: center;
    background-color:#FFFFFF;
    font-size: 16px;
    color: #000000;
    margin-top: -8px; /* Adjust this value to control the vertical position */
    padding-bottom: 10px; /* Add some padding at the bottom */
  }
