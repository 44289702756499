.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  }
  
  .feedback-modal {
    background-color: #FBF6ED;
    padding: 20px;
    border-radius: 0.5em;
    box-shadow: 0 0.25em 1em rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 400px;
    font-family: 'NoicheRegular', sans-serif;
  }
  
  .feedback-modal textarea {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;
    font-size: 16px;
    border: none;
    outline: none;
    padding: 10px;
    background-color: #FFEFD5;
    color: #333333;
    box-sizing: border-box;
    font-family: 'NoicheRegular', sans-serif;
  }
  
  .modal-actions button {
    background-color: #77E8D2;
    color: #000000;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    font-family: 'NoicheRegular', sans-serif;
  }
  
  .modal-actions button:hover {
    background-color: #62e6cb;
    transform: scale(1.05);
  }
  
  .modal-actions button:active {
    background-color: #77E8D2;
    transform: scale(1);
  }
  